<template>
  <div :class="['panel-title', divided ? 'border-bottom':'', showEnter ? 'pointer' : '']">
    <div class="flex items-center">
      <div class="title" :style="titleStyle" v-if="title">{{title}}</div>
      <img v-if="showEnter" class="img-right" src="@/assets/images/arrow-right-theme.png">
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    divided: {
      type: Boolean,
      default: false
    },
    showEnter: {
      type: Boolean,
      default: false
    },
    titleStyle: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
.panel-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  padding: 0 20px;
}
.border-bottom {
  border-bottom: 1px solid #F3F3F3;
}
.title {
  position: relative;
  padding-left: 10px;
  font-size: 14px;
  font-weight: bold;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 16px;
    width: 2px;
    background-color: var(--theme-color);
  }
}
.img-right {
  width: 16px;
  height: 16px;
  margin-left: 10px;
}
</style>