import request from '@/utils/request'

/**
  * 投诉列表
  */
export function reportedListApi(data = {}) {
  return request({
    url: 'report/reportedList',
    method: 'POST',
    data
  })
}
/**
  * 投诉详情
  */
export function reportDetailApi(data = {}) {
  return request({
    url: 'report/reportDetail',
    method: 'POST',
    data
  })
}

/**
  * 留言
  */
export function reportMsgApi(data = {}) {
  return request({
    url: 'report/reportMsg',
    method: 'POST',
    data
  })
}

/**
  * 投诉统计
  */
export function reportedStatics(data = {}) {
  return request({
    url: 'report/reportedStatics',
    method: 'POST',
    data
  })
}