<template>
  <div v-if="reportDetail">
    <NavTitle class="mb16" title="投诉详情"/>
    <div class="panel mt16">
      <div class="flex between-center">
        <PanelTitle title="投诉详情" :divided="false"/>
        <div :class="['desc-status', reportDetail.state == 1 ? 'theme-text' : 'red-text']" v-if="reportDetail.stateDesc">{{reportDetail.stateDesc}}</div>
      </div>
      <div class="content">
        <div class="c-title" v-if="reportDetail.reportTimeDesc">投诉时间：{{reportDetail.reportTimeDesc}}</div>
        <div class="c-title flex between-center">
          <div>被投诉商家：{{reportDetail.entName}}</div>
          <div class="flex items-center">
            <div class="enter-arrow" @click="toMerDetail()">
              <span>商家信息</span>
              <img src="@/assets/images/arrow-right-theme.png">
            </div>
            <div class="enter-arrow" @click="toReportList">
              <span>商家投诉</span>
              <img src="@/assets/images/arrow-right-theme.png">
            </div>
          </div>
        </div>
        
        <div class="c-contact" v-if="reportDetail.merchantContact">
          商家联系方式 
          <span>({{reportDetail.merchantContact.name}})</span>：
          <span>{{reportDetail.merchantContact.content}}</span>
        </div>
        <div class="c-title flex between-center">
          <div>投诉用户：{{reportDetail.reporterName}}</div>
          <div class="flex items-center">
            <div class="enter-arrow" @click="toUserDetail()">
              <span>用户信息</span>
              <img src="@/assets/images/arrow-right-theme.png">
            </div>
            <div class="enter-arrow" @click="toEnrollRecord">
              <span>报名记录</span>
              <img src="@/assets/images/arrow-right-theme.png">
            </div>
          </div>
        </div>
        <div class="c-contact" v-if="reportDetail.reporterContact">用户联系方式({{reportDetail.reporterContact.name}})：{{reportDetail.reporterContact.content || ''}}</div>
        <div class="c-row">投诉类型：{{reportDetail.typeDesc}}</div>
        <div class="c-row">投诉岗位：{{reportDetail.postName}}</div>
        <div class="c-row">投诉内容：{{reportDetail.content}}</div>
        <div class="c-row">投诉诉求：{{reportDetail.demand}}</div>
        <div class="img-list">
          <LoadImage class="img" :src="url" preview v-for="(url, index) in evidence" :key="index"></LoadImage>
        </div>
      </div>
    </div>
    <div class="panel mt16">
      <PanelTitle title="沟通记录" :divided="false"/>
      <div class="record-list" v-if="messageRecord && messageRecord.length > 0">
        <div v-for="(item, index) in messageRecord" :key="index">
          <div class="record-time">{{item.dt}}</div>
          <div class="record record-left" v-if="item.messageType == 0 && item.userType == 1">
            <img class="avatar" src="@/assets/images/company.png">
            <div class="wrap">
              <div class="name">{{item.fromUserName}}</div>
              <div class="msg-content">
                <div class="desc">{{item.content}}</div>
                <div class="img-list">
                  <LoadImage class="img" :src="url" preview v-for="(url, index) in item.pics" :key="index"></LoadImage>
                </div>
              </div>
            </div>
          </div>
          <div class="record record-right" v-if="item.messageType == 0 && item.userType == 2">
            <div class="wrap">
              <div class="name">{{item.fromUserName}}</div>
              <div class="msg-content">
                <div class="desc">{{item.content}}</div>
                <div class="img-list">
                  <LoadImage class="img" :src="url" preview v-for="(url, index) in item.pics" :key="index"></LoadImage>
                </div>
              </div>
            </div>
            <LoadImage class="avatar" :src="item.fromUidLogo" :default="require('@/assets/images/default-avatar.png')"></LoadImage>
          </div>
          <div class="record-status" v-if="item.messageType == 1 && item.content">
            <div class="title-wrap">
              <div class="icon-status">
                <img v-if="item.content.progress == 1 || item.content.progress == 2 || item.content.progress == 5" src="@/assets/images/icon-user.png">
                <img v-if="item.content.progress == 4" src="@/assets/images/icon-company.png">
                <img v-if="item.content.progress == 3 || item.content.progress == 6" src="@/assets/images/yes.png">
              </div>
              <div>
                <div>{{item.content.title}}</div>
                <div class="time">{{item.dt}}</div>
              </div>
            </div>
            <div class="desc" v-if="item.content.content">{{item.content.content}}</div>
          </div>
        </div>
        
      </div>
      <el-empty v-else description="暂无相关数据" :image="require('@/assets/images/nothing-empty.png')"></el-empty>
    </div>
  </div>
</template>

<script>
import NavTitle from '@/components/NavTitle'
import PanelTitle from '@/components/PanelTitle'
import LoadImage from '@/components/LoadImage'
import { reportDetailApi } from '@/api/report.js'
import { ossKeyToUrl } from '@/utils/ossKeyToUrl.js'
export default {
  name: 'ReportDetail',
  components: {
    NavTitle,
    PanelTitle,
    LoadImage
  },
  data() {
    return {
      pid: this.$route.query.pid,
      rid: this.$route.query.rid,
      reportDetail: null,
      evidence: [],
      messageRecord: []
    }
  },
  created() {
    this.getReportDetail()
  },
  methods: {
    getReportDetail() {
      console.log('pid', this.pid)
      console.log('rid', this.rid)

      reportDetailApi({
        pid: this.pid,
        rid: this.rid
      }).then(res => {
        console.log('投诉详情', res)
        this.reportDetail = res.data
        const evidence = res.data.evidence || []
        const messages = res.data.messages || []
        this.evidence = []
        evidence.forEach(img => {
          ossKeyToUrl({key: img}).then(ossRes => {
            this.evidence.push(ossRes.url)
          })
        })
        this.messageRecord = messages.map(item => {
          ossKeyToUrl({key: item.fromUidLogo}).then(ossRes => {
            item.fromUidLogo = ossRes.url
          })
          if(item.pics) item.pics.forEach((pic, index) => {
            ossKeyToUrl({key: pic}).then(ossRes => {
              this.$set(item.pics, index, ossRes.url)
            })
          })
          if(item.messageType == 1) {
            try {
              item.content = JSON.parse(item.content)
            } catch (err) {}
          }
          return item
        })
      }).catch(err => {
        this.$tips({message: err, type:'error'})
      })
    },
    toReportList() {
      this.$router.push({
        path: '/report/list',
        query: {
          mid: this.reportDetail.mid,
          // rid: this.rectifyDetail.rid
        }
      })
    },
    toEnrollRecord() {
      this.$router.push({
        path: '/user/enrollRecord',
        query: {
          uid: this.reportDetail.reporterUid
        }
      })
    },
    toMerDetail() {
      this.$router.push({
        path: '/detail/merchant',
        query: {
          mid: this.reportDetail.mid
        }
      })
    },
    toUserDetail() {
      this.$router.push({
        path: '/detail/user',
        query: {
          uid: this.reportDetail.reporterUid
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::-webkit-scrollbar {
	width: 8px;
	height: 6px;
}

::-webkit-scrollbar-thumb {
	background-color: #D9D9D9;
	border-radius: 32px;
}

::-webkit-scrollbar-track {
	background-color: #F6F6F6;
	border-radius: 32px;
}
.panel {
  background-color: #fff;
  border-radius: 8px;
  padding-bottom: 24px;
  margin-left: 20px;
  margin-right: 20px;
}
.desc-status {
  font-weight: bold;
  margin-right: 20px;
}
.content {
  padding: 0 20px 0 30px;
}
.c-title {
  font-size: 16px;
  font-weight: bold;
  color: #001A18;
  margin-bottom: 8px;
}
.c-contact {
  font-size: 15px;
  margin-bottom: 8px;
  color: #001A18;
}
.c-row {
  font-size: 14px;
  margin-bottom: 8px;
  color: #505E5C;
}
.img-list {
  display: flex;
  align-items: center;
  margin-top: 8px;
  .img {
    width: 68px;
    height: 68px;
    margin: 0 8px 8px 0;
    border-radius: 4px;
    cursor: pointer;
  }
}
.record-list {
  padding: 0 30px;
  max-height: 600px;
  overflow-y: auto;
  background-color: #f6f7f8;
}
.record-time {
  text-align: center;
  font-size: 12px;
  color: #505E5C;
  margin: 30px 0 10px;
}
.record {
  display: flex;
  margin-bottom: 26px;
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 8px;
    overflow: hidden;
  }
  .wrap {
    flex: 1;
    .name {
      font-size: 16px;
      font-weight: bold;
      color: #001A18;
      margin-bottom: 4px;
    }
    .msg-content {
      padding: 10px;
      background-color: #fff;
      border-radius: 6px;
      font-size: 14px;
    }
  }
}
.record-left {
  .wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.record-right {
  .wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  .avatar {
    margin-left: 10px;
  }
}
.record-status {
  background-color: #fff;
  color: #505E5C;
  padding: 12px;
  border-radius: 10px;
  margin-bottom: 26px;
  .icon-status {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    background-color: var(--theme-color);
    border-radius: 15px;
    img {
      width: 20px;
      height: 20px;
    }
  }
  .title-wrap {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: bold;
    .time {
      font-size: 12px;
      color: #505E5C;
      margin-top: 4px;
    }
  }
  .desc {
    margin-top: 8px;
    padding-top: 10px;
    border-top: 1px solid #f5f5f5;
  }
}

.enter-arrow {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: normal;
  color: var(--theme-color);
  cursor: pointer;
  margin-left: 10px;
  img {
    width: 13px;
    height: 13px;
  }
}
</style>